import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import { ReactSelect, RenderIf, RichTextEditor } from "../../../components";
import {
  useCreateCourseMutation,
  useGetAllCourseOrganizationQuery,
  useGetAllCourseTypeQuery,
  useGetAllHubDevCategoryQuery,
} from "../../../../redux/api/Library/libraryApi";
import { useGetUsersQuery } from "../../../../redux/api/Auth/Users/usersApi";
import { useGetInterestsWithoutFilterQuery } from "../../../../redux/api/Interests/interestsApi";
import { complexitiesOptions } from "../../../constants";
import { CreateSchema, initialValues } from "./data";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function Create(props) {
  const { show, onHide, refetch } = props;
  const [file, setFile] = useState();
  const [imageSrc, setImageSrc] = useState("");
  const {
    data: tagList,
    isSuccess: isTagListSuccess,
  } = useGetInterestsWithoutFilterQuery();
  const { data: courseTypes } = useGetAllCourseTypeQuery({});
  const { data: organizationTypes } = useGetAllCourseOrganizationQuery({});
  const {
    data: categoryTypes,
    isLoading: categoryLoading,
  } = useGetAllHubDevCategoryQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
    title: "",
  });
  const { data: users } = useGetUsersQuery({});
  const tagLists = tagList?.data;
  const [list, setList] = useState([]);
  const [expertOption, setExpertOption] = useState([]);
  const [expertOptionIds, setExpertOptionIds] = useState([]);
  const [selected, setSelected] = useState([]);
  const [tagsApi, SetTagsApi] = useState([]);
  const [newsTagsApi, SetNewsTagsApi] = useState([]);
  const [defaultVal, setDefaultVal] = useState([]);

  const handleOnChange = (value) => {
    const difference = selected?.filter((x) => !value.includes(x));
    difference?.forEach((opt) => {
      if (tagsApi?.includes(opt)) {
        const TagsApiClone = tagsApi?.filter((optApi) => optApi !== opt);
        SetTagsApi(TagsApiClone);
      } else {
        const newsTagsApiClone = newsTagsApi?.filter(
          (optApi) => optApi !== opt
        );
        SetNewsTagsApi(newsTagsApiClone);
      }
    });
    value?.forEach((val) => {
      if (!defaultVal?.includes(val)) {
        if (val?.__isNew__) {
          !newsTagsApi?.includes(val) && SetNewsTagsApi([...newsTagsApi, val]);
        } else {
          !tagsApi?.includes(val) &&
            !defaultVal?.includes(val) &&
            SetTagsApi([...tagsApi, val]);
        }
      }
    });
    setSelected(value);
  };
  const handleChangeExpert = (selectedOptions) => {
    const selectedIds = selectedOptions?.map((option) => option.value);
    setExpertOptionIds(selectedIds);
    setExpertOption(selectedOptions);
    formik.setFieldValue("experts", selectedIds);
  };
  const typeOptions = courseTypes?.data?.map((data) => ({
    value: data?.id,
    label: data?.title,
  }));
  const organizationOptions = organizationTypes?.data?.map((data) => ({
    value: data?.id,
    label: data?.title,
  }));
  const categoryOptions = categoryTypes?.data?.map((data) => ({
    value: data?.id,
    label: data?.title,
  }));

  const usersOption = users?.data?.map((data) => ({
    value: data?.id,
    label: data?.email,
  }));

  const onTypes = () => {
    const assets = tagLists?.map((suggestion) => ({
      value: suggestion.id,
      label: suggestion.name,
    }));
    setList(assets);
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };
  const imagePreview = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    setFile(file);
    formik.setFieldValue("iconFile", file);

    reader.onloadend = () => {
      setImageSrc(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const onClick = (e) => {
    setImageSrc("");
    setFile("");
  };

  const [
    createCourse,
    { isLoading, error, isSuccess },
  ] = useCreateCourseMutation();

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const form = useRef();
  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values) => {
      const formData = new FormData(form.current);

      formData.append("title", String(values.title));
      formData.append("description", String(values.description));
      formData.append("lenghtInMinutes", String(values.lenghtInMinutes));
      formData.append("hubDevCourseTypeId", String(values.hubDevCourseTypeId));
      formData.append("categoryId", String(values.categories));
      formData.append("scrollCourseId", String(values.scrollCourseId));
      formData.append("orderingId", String(values.scrollCourseId));

      formData.append("IFrameUrl", String(values.url));

      formData.append(
        "hubDevCourseOrganizationId",
        String(values.hubDevCourseOrganizationId)
      );
      formData.append("complexity", String(values.complexity));

      if (expertOptionIds?.length) {
        for (let i = 0; i < expertOptionIds?.length; i++) {
          formData.append("experts", expertOptionIds?.[i]);
        }
      }
      if (tagsApi?.length) {
        for (let i = 0; i < tagsApi?.length; i++) {
          formData.append("Interests", tagsApi?.[i].value);
        }
      }

      if (newsTagsApi?.length) {
        for (let i = 0; i < newsTagsApi?.length; i++) {
          formData.append("NewInterests", newsTagsApi?.[i].label);
        }
      }

      formData.append("iconFile", file);

      createCourse(formData);
    },
  });
  const { resetForm } = formik;
  const onHideModal = () => {
    onHide();
    resetForm();
    setImageSrc("");
    setFile("");
  };
  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
      resetForm();
    }
  }, [isLoading]);
  useEffect(() => {
    if (isTagListSuccess) onTypes();
  }, [isTagListSuccess]);

  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHideModal}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Add</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="title"
                    name="title"
                    label="Title"
                    placeholder="Title"
                    className="w-100"
                    error={getInputClasses("title")}
                    {...formik.getFieldProps("title")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.title && formik.errors.title}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.title}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="lenghtInMinutes"
                    name="lenghtInMinutes"
                    label="Duration"
                    placeholder="Duration"
                    className="w-100"
                    type="number"
                    error={getInputClasses("lenghtInMinutes")}
                    {...formik.getFieldProps("lenghtInMinutes")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.lenghtInMinutes &&
                    formik.errors.lenghtInMinutes
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.lenghtInMinutes}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="url"
                    name="url"
                    label="Url"
                    placeholder="Url"
                    className="w-100"
                    error={getInputClasses("url")}
                    {...formik.getFieldProps("url")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf condition={formik.touched.url && formik.errors.url}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.url}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="scrollCourseId"
                    name="scrollCourseId"
                    label="Scroll Course Id"
                    placeholder="Scroll Course Id"
                    className="w-100"
                    type="number"
                    error={getInputClasses("scrollCourseId")}
                    {...formik.getFieldProps("scrollCourseId")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.scrollCourseId &&
                    formik.errors.scrollCourseId
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.scrollCourseId}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="orderingId"
                    name="orderingId"
                    label="Order Id"
                    placeholder="Order Id"
                    className="w-100"
                    type="number"
                    error={getInputClasses("orderingId")}
                    {...formik.getFieldProps("orderingId")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.orderingId && formik.errors.orderingId
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.orderingId}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6 mt-4">
                <CreatableSelect
                  isMulti
                  onChange={handleOnChange}
                  value={selected}
                  options={list}
                  placeholder="Tags"
                  id="creatable-select"
                  instanceId="creatable-select"
                  className="creatable-select"
                  classNamePrefix="creatable-react-select"
                />
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <ReactSelect
                  options={typeOptions}
                  onChange={(value) =>
                    formik.setFieldValue("hubDevCourseTypeId", value.value)
                  }
                  placeholder="Course Type"
                  inputId="courseTypeId"
                  menuPosition="fixed"
                />
                <RenderIf
                  condition={
                    formik.touched.hubDevCourseTypeId &&
                    formik.errors.hubDevCourseTypeId
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.hubDevCourseTypeId}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <ReactSelect
                  options={organizationOptions}
                  onChange={(value) =>
                    formik.setFieldValue(
                      "hubDevCourseOrganizationId",
                      value.value
                    )
                  }
                  placeholder="Course Organization"
                  inputId="courseOrganizationId"
                  menuPosition="fixed"
                />
                <RenderIf
                  condition={
                    formik.touched.hubDevCourseOrganizationId &&
                    formik.errors.hubDevCourseOrganizationId
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.hubDevCourseOrganizationId}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <ReactSelect
                  options={categoryOptions}
                  onChange={(value) =>
                    formik.setFieldValue("categories", value.value)
                  }
                  placeholder="Course Category"
                  inputId="courseCategoryId"
                  menuPosition="fixed"
                />
                <RenderIf
                  condition={
                    formik.touched.categories && formik.errors.categories
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.categories}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <ReactSelect
                  options={complexitiesOptions}
                  onChange={(value) =>
                    formik.setFieldValue("complexity", value.value)
                  }
                  placeholder="Complexities"
                  inputId="complexitiesId"
                  menuPosition="fixed"
                />
                <RenderIf
                  condition={
                    formik.touched.complexity && formik.errors.complexity
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.complexity}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <ReactSelect
                  options={usersOption}
                  onChange={handleChangeExpert}
                  isMulti
                  single={expertOption}
                  placeholder="Experts"
                  inputId="expertId"
                  isSearchable
                  menuPosition="fixed"
                />
                <RenderIf
                  condition={formik.touched.experts && formik.errors.experts}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.experts}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6 mt-3">
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  name="iconFile"
                  label="File"
                  placeholder="File"
                  className="w-100"
                  onChange={imagePreview}
                />
                <RenderIf
                  condition={formik.touched.iconFile && formik.errors.iconFile}
                  renderElse={null}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.iconFile}
                    </div>
                  </div>
                </RenderIf>

                <RenderIf condition={imageSrc?.length}>
                  <div className="image-preview mt-4">
                    <div
                      style={{
                        position: "relative",
                        width: "100px",
                        height: "100px",
                      }}
                    >
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          borderRadius: "100%",
                        }}
                        src={imageSrc}
                        alt="img"
                      />
                      <button
                        style={{
                          position: "absolute",
                          right: "-30px",
                        }}
                        className="BtnSvg"
                        onClick={onClick}
                      >
                        <i
                          style={{ fontSize: "10px" }}
                          className="flaticon2-delete"
                        ></i>
                      </button>
                    </div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-12 mt-3">
                <RichTextEditor
                  onChange={(newContent) => {
                    const trimmedContent = newContent.trim();
                    const isEmpty = trimmedContent === "<p><br></p>";
                    formik.setFieldValue(
                      "description",
                      isEmpty ? "" : newContent
                    );
                  }}
                  value={formik?.values?.description}
                />
                <RenderIf
                  condition={
                    formik.touched.description && formik.errors.description
                  }
                  renderElse={null}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.description}
                    </div>
                  </div>
                </RenderIf>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
            }}
          >
            Close
          </Button>
          <Button type="submit" disabled={isLoading} className={`px-9`}>
            Add
            <RenderIf condition={isLoading}>
              <span className="ml-3 spinner spinner-white"></span>
            </RenderIf>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
